
import {defineComponent} from 'vue';
import Rating from "@/components/common/rating.vue";
import $ from "jquery";
import commonOnLoad from "@/mixins/commonOnLoad";

import store from "@/store";
import GoogleAnalytics from "@/components/common/googleanalytics.vue";

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ProfileView',
    components: {
        GoogleAnalytics,
        Rating
    },
    metaInfo: {
        title: 'Compétences'
    },
    mixins: [commonOnLoad],
    methods: {
    },
    computed: {
        withTracking() {
            return store.getters.withTracking;
        }
    },
    mounted() {
        this.updateHeightContainer(this.$route.name);
    },
    data() {
        return {
            skills: {
                'front': [
                    {
                        'name': 'HTML 5',
                        'rate': 95,
                        'color': 'purple',
                        'text': "Fort de nombreuses expériences acquises depuis 2015, j'ai pu, en" +
                            " combinaison d'une connaissance poussée du CSS 3 mettre en place des fonctionnalités " +
                            "visuelles avec un haut niveau d'interactivité."
                    },
                    {
                        'name': 'CSS 3',
                        'rate': 90,
                        'color': 'purple',
                        'text': "Que cela soit pour des sites 'desktop', 'mobile' ou applis mobile, une connaissance poussée du CSS3 m'a permis de mettre en place des designs élégants, fluides, interractifs et responsives. L'utilisation du précompilateur SASS est également un outil très bénéfique pour bien organiser son code"
                    },
                    {
                        'name': 'Javascript ES6',
                        'rate': 90,
                        'color': 'purple',
                    },
                    {
                        'name': 'Typescript',
                        'rate': 90,
                        'color': 'purple',
                        'text': "Je l'ai beaucoup pratiqué dans les frameworks Angular et VueJS. Il est d'un grande utilité pour organiser son modèle de données, avec nottament un formatage précis des données"
                    },
                    {
                        'name': 'JQuery',
                        'rate': 100,
                        'color': 'purple'
                    },
                    {
                        'name': 'Bootstrap',
                        'rate': 85,
                        'color': 'purple',
                        'text': "J'ai eu l'occasion de pratiquer ce framework CSS de façon poussée dans le cadre de plusieurs missions et nottement à la plateforme du bâtiment"
                    },
                    {
                        'name': 'Web GL',
                        'rate': 75,
                        'color': 'purple',
                        'text': "J'ai eu l'occasion de pratiquer la web GL grâce au framework ThreeJS sur plusieurs projets personnels. J'ai ainsi pu concevoir des animations 3D performantes, notement un simulateur de rubik's cube"
                    },
                    {
                        'name': 'VueJS',
                        'rate': 90,
                        'color': 'purple',
                        'text': "J'ai pratiqué ce framework Single Page dans le cadre de ma mission à la plateforme du bâtiment sur 3 applications. Je peux donc aujourd'hui me prévaloir d'une expertise sur ce framework. (\"Store\", API Rest (Axios), Gestion de l'appareil photo, geestion du cache (PWA), authentification Oauth2, Tests unitaires Jest, Test e2e Cypress). "
                    },
                    {
                        'name': 'Angular >2',
                        'rate': 90,
                        'color': 'purple',
                        'text': "J'ai pu pratiquer ce framework Single Page de manière avancée dans le cadre de ma mission à Akto / Fafih, en créant deux applications mobiles : Meetop / Le filV2 mais également sur l'applciation Simplimmat de l'ANTS"
                    },
                    {
                        'name': 'Ionic',
                        'rate': 75,
                        'color': 'purple',
                        'text': "Outil très puissant permettant de transformer une appli Single page en appli mobile (SDK) déployable. Combinée à Angular 5, j'ai pu déployer deux application mobiles sur l'\"app store\" d'apple et le \"playstore\" de Google dans le cadre de ma mission à Akto / Fafih"
                    }, {
                        'name': 'PWA',
                        'rate': 75,
                        'color': 'purple',
                        'text': "Très utile pour déployer des applications web/mobile hybride. J'ai mis en place une application \"tour de sécurité\" dur des PDA avec une gestion du cache et du fonctionnement \"hors ligne\""
                    },

                ],
                'back': [
                    {
                        'name': 'PHP 5 -> 8',
                        'rate': 90,
                        'color': 'blue',
                        'text': "J'ai pratiqué ce langage sur l'ensemble de mes missions WEB. J'estime avoir aujourd'hui une expertise reconnue sur ce langage. (web app MVC, traitements de fichier, authentification, websockets, batchs etc.)"
                    },
                    {
                        'name': 'Symfony 3 -> 6',
                        'rate': 90,
                        'color': 'blue',
                        'text': "Les missions à la Plateforme du bâtiment et à Simplimmat (ANTS) m'ont permis de mettre en pratique mes compétences sur Symfony. Que ça soit en utilisation MVC ou micro services, j'ai pu mettre en place des applications performantes reconnues par les utilisateurs (monolog, doctrine, JWT, Oauth2, commandes/batchs, subscribers, etc)"
                    },
                    {
                        'name': 'API REST',
                        'rate': 90,
                        'color': 'blue',
                    },
                    {
                        'name': 'Auth. LDAP',
                        'rate': 70,
                        'color': 'blue',
                    },
                    {
                        'name': 'Authent. Oauth 2',
                        'rate': 100,
                        'color': 'blue',
                        'text': "L'authentification unique (SSO) est aujourd'hui très en vogue. J'ai pu l'implémenter (protocole SSO) sur plusieurs projets à la plateforme du bâtiment, sur une web-app  Symfony et sur deux applis VueJS"
                    },
                    {
                        'name': 'Node JS',
                        'rate': 60,
                        'color': 'blue',
                    },
                    {
                        'name': 'MySQL / PostgreSQL',
                        'rate': 85,
                        'color': 'blue',
                    },
                    {
                        'name': 'MongoDB',
                        'rate': 60,
                        'color': 'blue',
                    },
                    {
                        'name': 'IndexedDB',
                        'rate': 90,
                        'color': 'blue',
                        'text': "Très utile pour stocker des données complexes dans le navigateur, ce procédé m'a permis de faire fonctionner l'appli \"Tour de Sécurité\" hors connexion"
                    },

                ],
                'devops': [
                    {
                        'name': 'Linux Debian/Red hat',
                        'rate': 80,
                        'color': 'black',
                    },
                    {
                        'name': 'Docker',
                        'rate': 60,
                        'color': 'black',
                    },
                    {
                        'name': 'Vagrant/VirtualBox',
                        'rate': 75,
                        'color': 'black',
                    },
                    {
                        'name': 'Apache',
                        'rate': 70,
                        'color': 'black',
                    },
                    {
                        'name': 'Nginx',
                        'rate': 70,
                        'color': 'black',
                    },
                    {
                        'name': 'Git/BitBucket/Gitlab',
                        'rate': 75,
                        'color': 'black',
                    },
                    {
                        'name': 'Gitlab CICD',
                        'rate': 70,
                        'color': 'black',
                    },

                ],
                'misc': [
                    {
                        'name': 'PostMan',
                        'rate': 90,
                        'color': 'misc',
                    },
                    {
                        'name': 'Wordpress',
                        'rate': 60,
                        'color': 'misc',
                    },
                    {
                        'name': 'Joomla',
                        'rate': 55,
                        'color': 'misc',
                    },
                    {
                        'name': 'SEO',
                        'rate': 80,
                        'color': 'misc',
                    }
                ]
            }
        }
    }
});
